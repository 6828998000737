<template>
  <div class="box">
    <div class="search">

      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="订单号：" prop="orderId">
              <el-input v-model="queryInfo.condition.orderId" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请时间：" prop="industryType">
              <el-date-picker @change="change" v-model="valuechange" style="width: 100%;" type="daterange"
                start-placeholder="开始日期" end-placeholder="结束日期" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="发票类型：" prop="invoiceType">
              <el-input v-model="queryInfo.condition.invoiceType" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="联系邮箱：" prop="id">
              <el-input v-model="queryInfo.condition.email" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开票状态：" prop="status">
              <el-input v-model="queryInfo.condition.status" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="display: flex; justify-content: flex-end">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>
          <!-- <el-col :span="8">
              <el-form-item label="支付方式：" prop="industryType">
                <el-select v-model="queryInfo.condition.payChanel" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
        </el-row>
        <!-- <el-row>
            <el-col :span="8">
              <el-form-item label="交易时间：" prop="industryType">
                <el-date-picker @change="change" v-model="valuechange" style="width: 100%;" type="daterange" start-placeholder="开始日期"
                  end-placeholder="结束日期" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="操作员：" prop="businessStatus">
                <el-input v-model="queryInfo.condition.name" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :span="8" style="display: flex; justify-content: flex-end">
              <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
              <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
            </el-col>
  
          </el-row> -->
      </el-form>
    </div>
    <div class="search-top">
      <div :class="i == tapshow ? 'active' : ''" v-for="(item, i) in toplist" :key="i" @click="tapchange(i)">{{
        item.name }}
      </div>
    </div>


    <div v-show="tapshow == 0">
      <el-table :data="tableData" style="width: 100%" height="calc(100vh - 320px)" border :header-cell-style="{
        'background-color': '#f2f2f2',
        'text-align': 'center',
        border: '1px solid #ddd',
      }">
        <el-table-column prop="transactionId" label="订单号" width="160" align="center">
        </el-table-column>
        <el-table-column prop="headName" label="开票抬头" width="160" align="center">
        </el-table-column>
        <el-table-column prop="taxNo" label="税号" width="160" align="center">
        </el-table-column>
        <el-table-column prop="invoice" label="订单金额" width="180" align="center">
        </el-table-column>
        <el-table-column prop="invoiceType" label="发票类型" width="100" align="center">
        </el-table-column>
        <el-table-column prop="headType" width='150' label="开票单位类型" align="center">
          <template slot-scope="scope">
            <el-button type="text">{{ scope.row.headType == 0 ? '个人' : '企业' }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="email" width='200' label="联系邮箱" align="center">
        </el-table-column>
        <el-table-column prop="creatPhone" label="手机号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="creatTime" label="申请时间" width="150" align="center">
        </el-table-column>
        <el-table-column prop="status" label="开票状态" align="center">
          <template slot-scope="scope">
            <el-button type="text">{{ scope.row.status == 1 ? '待开票' : '已开票' }}</el-button>
          </template>
        </el-table-column>

        <el-table-column prop=" " label="操作" width="200" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.status == 1" @click="doInvoice(scope.row.id)">确认开票</el-button>
            <el-button type="text" @click="deta(scope.row.id)">开票申请详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <div v-show="tapshow == 1">
      <el-table :data="tableData" style="width: 100%" height="calc(100vh - 320px)" border :header-cell-style="{
        'background-color': '#f2f2f2',
        'text-align': 'center',
        border: '1px solid #ddd',
      }">
        <el-table-column prop="transactionId" label="订单号" width="160" align="center">
        </el-table-column>
        <el-table-column prop="headName" label="开票抬头" width="160" align="center">
        </el-table-column>
        <el-table-column prop="taxNo" label="税号" width="160" align="center">
        </el-table-column>
        <el-table-column prop="invoice" label="订单金额" width="180" align="center">
        </el-table-column>
        <el-table-column prop="invoiceType" label="发票类型" width="100" align="center">
        </el-table-column>
        <el-table-column prop="headType" width='150' label="开票单位类型" align="center">
          <template slot-scope="scope">
            <el-button type="text">{{ scope.row.headType == 0 ? '个人' : '企业' }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="email" width='200' label="联系邮箱" align="center">
        </el-table-column>
        <el-table-column prop="creatPhone" label="手机号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="creatTime" label="申请时间" width="150" align="center">
        </el-table-column>
        <el-table-column prop="status" label="开票状态" align="center">
          <template slot-scope="scope">
            <el-button type="text">{{ scope.row.status == 1 ? '待开票' : '已开票' }}</el-button>
          </template>
        </el-table-column>

        <el-table-column prop=" " label="操作" width="200" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.status == 1" @click="doInvoice(scope.row.id)">确认开票</el-button>
            <el-button type="text" @click="deta(scope.row.id)">开票申请详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <div v-show="tapshow == 2">
      <el-table :data="tableData" style="width: 100%" height="calc(100vh - 320px)" border :header-cell-style="{
        'background-color': '#f2f2f2',
        'text-align': 'center',
        border: '1px solid #ddd',
      }">
        <el-table-column prop="transactionId" label="订单号" width="160" align="center">
        </el-table-column>
        <el-table-column prop="headName" label="开票抬头" width="160" align="center">
        </el-table-column>
        <el-table-column prop="taxNo" label="税号" width="160" align="center">
        </el-table-column>
        <el-table-column prop="invoice" label="订单金额" width="180" align="center">
        </el-table-column>
        <el-table-column prop="invoiceType" label="发票类型" width="100" align="center">
        </el-table-column>
        <el-table-column prop="headType" width='150' label="开票单位类型" align="center">
          <template slot-scope="scope">
            <el-button type="text">{{ scope.row.headType == 0 ? '个人' : '企业' }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="email" width='200' label="联系邮箱" align="center">
        </el-table-column>
        <el-table-column prop="creatPhone" label="手机号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="creatTime" label="申请时间" width="150" align="center">
        </el-table-column>
        <el-table-column prop="status" label="开票状态" align="center">
          <template slot-scope="scope">
            <el-button type="text">{{ scope.row.status == 1 ? '待开票' : '已开票' }}</el-button>
          </template>
        </el-table-column>

        <el-table-column prop=" " label="操作" width="200" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="deta(scope.row.id)">开票申请详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog title="开票申请" :visible.sync="showDialogtwo" width="610px" height="1000px">
      <div class="diatwo">
        <div class="diat">发票信息</div>
        <div class="diad">
          <div>发票抬头：{{ detail.headName }}</div>
          <div>发票抬头类型：{{ detail.headType == 0 ? '个人' : '企业' }}</div>
          <div>发票类型：{{ detail.invoiceType }}</div>
          <div>发票金额：{{ detail.invoice }}</div>
          <div>发票内容：{{ detail.invoiceContent }}</div>
        </div>
        <div class="diat">发票信息</div>
        <div class="diad2">
          <div>真实姓名：{{ detail.creatName }}</div>
          <div>联系电话：{{ detail.creatPhone }}</div>
          <div>联系邮箱：{{ detail.email }}</div>
          <div>邮寄地址：{{ detail.adress }}</div>
        </div>
        <div class="diad3">
          <div>关联订单编号：{{ detail.orderId }}</div>
          <div>发票编号：{{ detail.transactionId }}</div>
          <div>发票备注：{{ detail.remark }}</div>
          <div>开票时间：{{ detail.creatTime }}</div>
        </div>
        <div class="but">
          <el-button type="danger" @click="nook(detail.id)">拒绝开票</el-button>
          <el-button type="primary" @click="ok(detail.id)">确认开票</el-button>
        </div>




      </div>
    </el-dialog>

  </div>

</template>

<script>
export default {
  data() {
    return {
      detail: {},
      showDialogtwo: false,
      showDialogsale: false,
      toplist: [{
        name: '全部发票',
        value: ''
      }, {
        name: '待开票',
        value: ''
      }, {
        name: '已开票',
        value: ''
      }],
      tableData: [],
      total: 0,
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {
          orderId: '',
          invoiceType: '',
          email: '',
          status: '',
          startDate: '',
          endDate: ''
        }
      },
      valuechange: '',
      form: {},
      tapshow: 0,
      options: [{
        value: '1',
        label: '店长'
      }, {
        value: '2',
        label: '员工'
      }],
      afterSalesType: '1', //是否全部退款
      afterSalesDealMessage: '',//审核内容
      afterSalesNum: "",//退款金额
      detail: {}

    };
  },
  created() {
    this.getList()
  },
  mounted() {

  },
  methods: {
    change() {
      console.log(this.valuechange);
      this.queryInfo.condition.startDate = this.newDate(this.valuechange[0])
      this.queryInfo.condition.endDate = this.newDate(this.valuechange[1])
    },
    newDate(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    //获取数据
    getList() {
      var that = this;
      that.$http.post("/invoice/list", that.queryInfo).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
          console.log(that.tableData);

        }
      });
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
    search() {
      // this.queryInfo.condition = this.form
      this.getList()
    },
    reset() {
      // this.form = {}
      this.queryInfo.condition = {}
      this.queryInfo.condition.totalOrderType = this.tapshow + 1
      this.getList()
    },
    tapchange(i) {
      this.tapshow = i
      this.queryInfo.currPage = 1
      if (i == 0) {
        this.queryInfo.condition.status = ''

        this.getList()
      }
      if (i == 1) {
        this.queryInfo.condition.status = 1
        this.getList()
      }
      if (i == 2) {
        this.queryInfo.condition.status = 2
        this.getList()
      }


    },
    doInvoice(id) {
      var that = this;
      that.$http.post("/invoice/update", {
        id: id,
        status: 2
      }).then(function (response) {
        if (response.data.code == 200) {
          this.getList()
        }
      });
    },
    deta(id) {

      var that = this;
      that.showDialogtwo = true
      that.$http.post("/invoice/queryOne", {
        id: id
      }).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {
          that.detail = response.data.data

        }
      });

    },
    sale(id) {
      var that = this;
      that.$http.post("/order/adminQueryOrderDetail", {
        id: id
      }).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {
          that.detail = response.data.data
          that.showDialogsale = true
        }
      });


    },
    submitForm() {
      var that = this;
      that.$http.post("/order/merchantHandleAfterSale", {
        orderType: that.detail.orderType,
        id: that.detail.id,
        businessId: that.detail.businessId,
        afterSalesDealMessage: that.afterSalesDealMessage,
        afterSalesType: that.afterSalesType,
        afterSalesNum: that.afterSalesNum

      }).then(function (response) {

        if (response.data.code == 200) {
          that.$message.success('success');
          that.showDialogsale = false
        }
      });
    },
    ok() {
      var that = this;
      that.$http.post("/invoice/update", {
        status: 2,
        id: that.detail.id
      }).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {

          that.showDialogtwo = false
          that.getList()
        }
      });
    },
    nook() {
      var that = this;
      that.$http.post("/invoice/update", {
        status: 3,
        id: that.detail.id
      }).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {

          that.showDialogtwo = false
          that.getList()
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 10px;
    padding-bottom: 0;


  }

  .search-top {
    height: 33px;
    padding: 2px 2px 2px 2px;
    background-color: rgba(30, 152, 215, 0.14);
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    div {
      width: 150px;
      text-align: center;
      height: 33px;
      line-height: 33px;
    }

    .active {
      border-bottom: 2px solid #2097f6;
      color: #2097f6;
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px
  }

  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 130px;
    }
  }

  .drawer {
    height: 900px;
    overflow-y: scroll;

    .drauser {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;

      div {
        width: 30%;
        color: #847575;
        margin-bottom: 30px;

        span {
          color: #000;
        }
      }
    }

    .draimg {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;

      .imgbox {
        width: 30%;
        display: flex;

        .imgname {
          color: #000;
        }

        .img {
          margin-top: 40px;
          display: flex;

          img {
            width: 89px;
            height: 65px;
          }
        }

      }
    }

    .bl {
      padding: 0 30px;

      .bl-li {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .bls {
          display: flex;
          align-items: center;
          margin-left: 15px;

          input {
            width: 100px;
            height: 32px;
            text-align: center
          }

          .text-box {
            width: 38px;
            height: 38px;
            background-color: rgba(230, 230, 230, 0.55);
            text-align: center;
            line-height: 38px;
          }
        }
      }
    }

    .qx {
      padding: 0 30px;
      margin-top: 30px;
    }
  }

  .diatwo {
    box-sizing: border-box;
    padding: 0 30px;
    font-size: 12px;
    color: #000;

    .diat {
      font-size: 12px;
      padding-top: 5px;
      font-weight: 700;
    }

    .diad {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #f5f5f5;
      padding-bottom: 10px;

      div {
        width: 170px;
        margin-top: 10px;
      }
    }

    .diad2 {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #f5f5f5;
      padding-bottom: 10px;

      div {
        width: 250px;
        margin-top: 10px;
      }
    }

    .diad3 {
      div {
        margin-top: 10px;
      }
    }

    .but {
      display: flex;
      justify-content: space-between;
      padding: 0 170px;
      margin-top: 15px;
    }


  }

  .diasale {
    color: #333333;
    font-size: 16px;

    .sale1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    .sale2 {
      margin-top: 5px;

      .de2 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .de-left {
          display: flex;

          img {
            width: 66px;
            height: 68px;
            border-radius: 5px;
          }

          .de-jj {
            margin-left: 5px;

            div {
              height: 33px;
              line-height: 33px;
            }

          }
        }

        .de-right {
          color: #e30606;
        }
      }

      .de3 {
        margin-top: 10px;

        .de-img {
          display: flex;

          img {
            width: 66px;
            height: 68px;
            border-radius: 5px;
            margin-right: 10px;
          }
        }

        border-bottom: 1px solid #e30606;
        padding-bottom: 40px
      }
    }

    .sale3 {
      display: flex;
      justify-content: space-between;
      margin-top: 20px
    }

    .sale4 {
      margin-top: 20px;
      display: flex;

      textarea {
        width: 680px;
        height: 60px;
        padding: 2px 2px 2px 2px;
        border-radius: 5px;
        border: 1px solid rgba(121, 121, 121, 0.26);
      }
    }
  }
}
</style>